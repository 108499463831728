@import "../../_globalColor";

.about-heading {
  font-size: 56px;
  font-weight: 400;
}
.subTitle {
  color: $subTitle;
}
.about-content-container {
  display: flex;
}
.about-paragraphs-container {
  flex: 2;
}
.about-image-div {
  flex: 1;
  padding: 10px 30px;
}
.about-image-div > img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Media Query */
@media (max-width: 1380px) {
  .about-heading {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .about-heading {
    font-size: 30px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
  }
  .about-content-container {
    display: block;
  }
  .about-text-div {
    margin: 20px;
  }
  .about-text {
    font-size: 16px;
  }
  .about-text-subtitle {
    font-size: 16px;
    text-align: center;
  }
}
