@import "../../_globalColor";

.gallery-heading {
  font-size: 56px;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}
.gallery-subtitle {
  text-transform: uppercase;
  margin: 0px;
  margin-bottom: 50px;
}
.subTitle {
  color: $subTitle;
}
.gallery-cards-div {
  height: 550px;
  width: 95%;
  margin: auto;
}
.gallery-cards-image {
  height: 500px;
  width: 100%;
  object-fit: cover;
  object-position: left top;
}
.gallery-view-more {
  text-align: right;
  width: 95%;
}

/* Media Query */
@media (max-width: 1380px) {
  .gallery-heading {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .gallery-heading {
    font-size: 30px;
    text-align: center;
  }
  .gallery-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
  .gallery-cards-div {
    height: 400px;
  }
  .gallery-cards-image {
    height: 350px;
  }
}
